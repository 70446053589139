export const eyebrowAnimation = {
  opacity: 0,
  duration: 1.25,
  stagger: {
    from: 'random',
    amount: 1,
  },
}

export const headlineAnimation = {
  opacity: 0,
  rotateX: 100,
  rotateY: 7,
  rotateZ: 9,
  y: '50%',
  scale: 0.8,
  duration: 1.5,
  ease: 'power2.out',
  transformOrigin: 'center',
  stagger: 0.2,
  lazy: false,
}

export const dividerAnimationLeft = {
  scaleX: 0,
  opacity: 0.5,
  duration: 1.5,
  ease: 'power2.out',
  transformOrigin: 'left',
}

export const dividerAnimationCenter = {
  scaleX: 0,
  opacity: 0.5,
  duration: 1.5,
  ease: 'power2.out',
  transformOrigin: 'center',
}

export const bodyCopyAnimation = {
  opacity: 0,
  y: 15,
  duration: 1.5,
  stagger: 0.15,
  ease: 'power2.out',
  lazy: false,
}

export const bodyCopyAnimationFast = {
  opacity: 0,
  y: 15,
  duration: 1.5,
  stagger: {
    amount: 2,
  },
  ease: 'power2.out',
  lazy: false,
}

export const mediaCardSlideAnimation = {
  opacity: 0,
  duration: 1,
  ease: 'power3.out',
  y: 50,
}
