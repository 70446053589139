export * from 'gsap';
export * from 'gsap/ScrollTrigger';

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import GlobalEmitter from '~/glxp/utils/emitter'

gsap.registerPlugin(ScrollTrigger)
gsap.defaults({
  // lazy: false
})

GlobalEmitter.on('loader_animation_end', () => {
  ScrollTrigger.refresh()
})

GlobalEmitter.on('debouncedResize', () => {
  ScrollTrigger.refresh()
})

export default gsap;
